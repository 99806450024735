.select__columns {
    padding: 0;
}

.col_selection {
    width: 100%;
}

.save_col_options input {
    margin-top: -5px;
    margin-right: 3px;
}

.save_col_options label {
    margin-right: 10px;
}

.font-italic {
    font-size: 1rem !important;
}

.show_columns__label {
    font-size: 13px;
    opacity: 0.8;
}

.column_modal {
    height: 100%;
}

.modal__div > div > div {
    /* position: absolute !important;
    height: unset !important; */
    /* top: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; */
}