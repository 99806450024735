.store__container{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 24px 18px 24px 10px;
    width: 100%;
    max-width: 100%;
}

.store__tables{
    display: flex;
    flex-direction: column;
}

a.nav-item.nav-link {
    border: none;
    font-size: 1.3rem;
    color: var(--color-neutral-a-s-b) !important;
}

a.nav-item.nav-link.active {
    border: none;
    font-size: 1.3rem;
    color: var(--color-primary) !important;
    font-weight: 800 !important;
    border-bottom: 3px solid var(--color-primary) !important;;
}

.nav-tabs{
    margin-left: 15px !important;
}
 
.store__group__table, .stores__table{
    align-self: center;
    margin: 20px 0;
    max-width: 94%;
}

.stores__table{
    display: flex;
    flex-direction: column;
}

.stores__table > div {
    max-width: 100%;
}

.storesStore__heading {
    display: flex;
    align-items: flex-start;
}

.storesStore__heading h1 {
    flex: 0.5;
}



  .action-cell-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }


